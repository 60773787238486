import React from 'react'
import styled from '@emotion/styled'
import ICONS from 'assets/icons/automations'
import { mediaQueries } from '../../../theme'
import { mainHeadingStyles } from '../../shared/type'
import Container from '../../shared/container'
import { MediaCard, ImageCard as BaseImageCard } from '../../shared/cards'
import MediaCardCarousel from '../shared/media-card-carousel'
import BaseCarouselGrid from '../../shared/carousel-grid'

const CarouselGrid = styled(BaseCarouselGrid)`
  margin-bottom: ${p => p.theme.space[4]};

  ${mediaQueries.md} {
    margin-bottom: ${p => p.theme.space[8]};
  }
`

const Title = styled.h2`
  ${mainHeadingStyles};
  margin: 0 0 ${p => p.theme.space[6]} 0;

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[14]};
  }
`

const ImageCard = styled(BaseImageCard)`
  min-width: 325px;
  min-height: 292px;

  ${mediaQueries.md} {
    min-width: 100px;
  }
`

const Automations = ({ data: { features, cards }, className }) => {
  const featureCards = features.map(
    ({ title, description, name, classes }) => ({
      title,
      description,
      image: ICONS[name],
      className: classes,
    }),
  )

  return (
    <Container className={className}>
      <Title>Automate email workflows</Title>

      <CarouselGrid storageKey="automate-email-workflows-features-carousel">
        {featureCards.map((card, idx) => (
          <ImageCard key={idx} {...card} />
        ))}
      </CarouselGrid>

      <MediaCardCarousel storageKey="automate-email-workflows-carousel">
        {cards.map((card, idx) => (
          <MediaCard key={idx} {...card} />
        ))}
      </MediaCardCarousel>
    </Container>
  )
}

export default Automations
