import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { mediaQueries } from '../../../theme'
import BaseLink from '../link'

export const BaseTemplateLink = ({
  target: { wid },
  templates = [],
  defaultDevice,
  defaultTheme,
  ariaLabel,
  children,
  ...rest
}) =>
  wid ? (
    <BaseLink
      to={`/p/${wid}/`}
      ariaLabel={ariaLabel}
      state={{
        modal: true,
        templates: templates.map(t => t.wid),
        defaultDevice,
        defaultTheme,
      }}
      {...rest}
    >
      {children}
    </BaseLink>
  ) : (
    <div {...rest}>{children}</div>
  )

const Link = styled(BaseTemplateLink)`
  display: block;
  border: 2px solid transparent;
  color: ${p => p.theme.colors.text};
  background-color: ${p => p.theme.colors.template.background};
  ${mediaQueries.lg} {
    border-width: 4px;
    &:hover {
      color: ${p => p.theme.colors.template.colorHover};
      background-color: ${p => p.theme.colors.template.backgroundHover};
    }
  }
`

const LinkText = styled.div`
  font-size: ${p => p.theme.fontSizes[1]};
  text-align: center;
  padding: ${p => `${p.theme.space[3]} 0 ${p.theme.space[2]}`};
  ${mediaQueries.lg} {
    font-size: ${p => p.theme.fontSizes[2]};
  }
`

const TemplateLink = ({
  target: { title = '', description = '', image, wid },
  templates,
  defaultTheme,
  loading = 'lazy',
  className,
  children,
}) => (
  <Link
    className={className}
    target={{ wid }}
    templates={templates}
    defaultTheme={defaultTheme}
  >
    {image && (
      <GatsbyImage
        image={getImage(image)}
        objectFit="contain"
        alt={description || title}
        loading={loading}
      />
    )}
    {children && <LinkText>{children}</LinkText>}
  </Link>
)

export default TemplateLink
