import React from 'react'
import { css } from '@emotion/react'

export const Vr = ({ color = '#000', className }) => (
  <svg
    width="3"
    height="100%"
    css={css`
      display: block;
    `}
    className={className}
  >
    <line
      x1="2"
      x2="2"
      y1="100%"
      y2="2"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeDasharray=".001,12"
    />
  </svg>
)

export const Hr = ({ color = '#000', className }) => (
  <svg
    width="100%"
    height="3"
    css={css`
      display: block;
    `}
    className={className}
  >
    <line
      x1="2"
      x2="100%"
      y1="2"
      y2="2"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeDasharray=".001,12"
    />
  </svg>
)
