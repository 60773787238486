import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { mediaQueries } from '../../../theme'
import BaseCta, { Title } from './cta'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${p => p.theme.colors.black[0]};

  ${mediaQueries.md} {
    flex-direction: row;
  }
`

const Image = styled(GatsbyImage)`
  max-height: 13.4375rem;

  ${mediaQueries.sm} {
    max-height: 22.5rem;
  }

  ${mediaQueries.md} {
    max-height: none;
    width: 50%;
  }
`

const Cta = styled(BaseCta)`
  flex: 1;

  ${Title} {
    ${mediaQueries.lg} {
      font-size: ${p => p.theme.fontSizes[13]};
    }
  }
`

const CustomerCare = ({ images, className }) => {
  const mobileImage = images.find(({ name }) => name === 'mobile')
  const desktopImage = images.find(({ name }) => name === 'desktop')

  const imagesWithArtDirection = withArtDirection(getImage(desktopImage), [
    {
      media: `(max-width: 750px)`,
      image: getImage(mobileImage),
    },
  ])

  return (
    <Container className={className}>
      <Image
        image={imagesWithArtDirection}
        alt="Customer care"
        loading="lazy"
      />
      <Cta />
    </Container>
  )
}

export default CustomerCare
