import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../../theme'
import TemplateImageLink from '../shared/template-image-link'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${p => `${p.theme.space[2]} 0`};

  ${mediaQueries.lg} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    align-content: start;
    gap: ${p => `${p.theme.space[1]} ${p.theme.space[2]}`};
    min-height: 667px;
    padding: 0;
  }
`

const DesktopTemplate = styled.div`
  display: none;
  position: relative;
  padding: ${p =>
    `${p.theme.space[5]} ${p.theme.space[4]} ${p.theme.space[4]}`};

  ${mediaQueries.lg} {
    display: block;
  }
`

const Title = styled.p`
  position: absolute;
  min-width: 13.125rem;
  margin: 0;
  padding: 0.5rem;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 20px;
  background-color: ${p => p.theme.colors.yellow[0]};
  font-size: ${p => p.theme.fontSizes[1]};
  font-weight: ${p => p.theme.fontWeights.heading};
  text-align: center;
  text-transform: uppercase;
  z-index: 3;
`

const ImageDesktop = styled(TemplateImageLink)`
  box-shadow: ${p => p.theme.shadows.card};
  z-index: 1;
`

const ImageMobile = styled(TemplateImageLink)`
  display: block;
  width: 100%;

  ${mediaQueries.lg} {
    display: none;
  }
`

const Slide = ({ desktopTemplates, mobileTemplate, className, ...rest }) => (
  <Container className={className} {...rest}>
    {desktopTemplates.map((item, index) => (
      <DesktopTemplate key={index}>
        <Title>{item.name}</Title>
        <ImageDesktop
          target={{ wid: item.wid, image: item.image }}
          defaultTheme="light"
        />
      </DesktopTemplate>
    ))}
    <ImageMobile
      target={{ wid: mobileTemplate.wid, image: mobileTemplate.image }}
      defaultTheme="light"
      defaultDevice="mobile"
    />
  </Container>
)

export default Slide
