import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../../theme'
import TemplateStack from '../shared/template-stack'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${p => `${p.theme.space[2]} 0`};

  ${mediaQueries.lg} {
    padding: ${p =>
      `${p.theme.space[4]} ${p.theme.space[4]} ${p.theme.space[29]}`};
  }
`

const Slide = ({ images, wid, className }) => (
  <Container className={className}>
    <TemplateStack
      front={images.large}
      back={images.small}
      mobile={images.mobile}
      wid={wid}
      className={className}
    />
  </Container>
)

export default Slide
