import React from 'react'
import styled from '@emotion/styled'

import {
  CheckMarkIcon,
  LinkIcon,
  OrderIcon,
  QuestionIcon,
} from 'assets/icons/templates'

import { EmailIcon } from 'assets/icons/social'

import { mediaQueries } from '../../../theme'
import { Thumbnails, Slides, useCarousel } from '../../shared/carousel'
import { H2, mainHeadingStyles } from '../../shared/type'
import Thumbnail from '../shared/thumbnail'
import Slide from './slide'

const ICONS = {
  email: EmailIcon,
  link: LinkIcon,
  order: OrderIcon,
  checkmark: CheckMarkIcon,
  question: QuestionIcon,
}

const Container = styled.div`
  display: grid;
  padding: 0;
  position: relative;

  ${mediaQueries.lg} {
    grid-template-columns: 300px 1fr;
    grid-template-areas:
      'title title'
      'thumbnails slides';
    grid-column-gap: ${p => p.theme.space[15]};
    align-items: start;
    padding: ${p => `0 ${p.theme.space[10]}`};
  }

  ${mediaQueries.xl} {
    padding: ${p => `0 ${p.theme.space[22]} 0 ${p.theme.space[21]}`};
  }
`

const Title = styled(H2)`
  ${mainHeadingStyles}
  padding: 0 ${p => p.theme.space[6]};
  margin: ${p => `0 0 ${p.theme.space[6]}`};

  ${mediaQueries.md} {
    padding: 0 ${p => p.theme.space[10]};
  }

  ${mediaQueries.lg} {
    grid-area: title;
    padding: 0;
    margin: ${p => `0 0 ${p.theme.space[14]}`};
  }
`

const TemplateThumbnails = styled(Thumbnails)`
  margin: ${p => `0 ${p.theme.space[6]}`};
  padding-top: ${p => `${p.theme.space[2]}`};

  ${mediaQueries.lg} {
    padding-top: ${p => `${p.theme.space[4]}`};
  }
  ${mediaQueries.md} {
    margin: ${p => `0 ${p.theme.space[10]}`};
  }

  ${mediaQueries.lg} {
    grid-area: thumbnails;
    max-width: 25rem;
    justify-self: flex-end;
    margin: ${p => `0 0 ${p.theme.space[8]} 0`};
  }
`

const TemplateThumbnail = styled(Thumbnail)`
  margin-right: ${p => p.theme.space[2]};
  min-width: 74%;

  &:last-child {
    margin-right: 0;
  }

  ${mediaQueries.md} {
    min-width: 40%;
  }

  ${mediaQueries.lg} {
    margin: ${p => `0 0 ${p.theme.space[2]} 0`};

    &:last-child {
      margin: 0;
    }
  }
`

const TemplatesSlides = styled(Slides)`
  margin: ${p => `0 ${p.theme.space[1]}`};
  padding: ${p => `${p.theme.space[5]} 0 0`};

  ${mediaQueries.lg} {
    grid-area: slides;
    max-width: 804px;
    padding: 0;
  }
`

const TemplatsSlide = styled(Slide)`
  min-width: 100%;
`

const Templates = ({ groups, className }) => {
  const { slidesRef, thumbnailsRef, selectedIndex, select } = useCarousel(
    'templates-carousel',
    {
      desktop: {
        draggable: false,
        speed: 100,
      },
    },
  )

  const thumbnails = groups.map(({ title, description, name }) => {
    return { title, description, icon: ICONS[name] }
  })

  const slides = groups.map(({ wid, images }) => {
    return { wid, images }
  })

  return (
    <Container className={className}>
      <Title>Design any type of form</Title>
      <TemplateThumbnails ref={thumbnailsRef}>
        {thumbnails.map((thumb, index) => (
          <TemplateThumbnail
            key={index}
            {...thumb}
            size="small"
            selected={index === selectedIndex}
            onClick={() => select(index)}
          />
        ))}
      </TemplateThumbnails>

      <TemplatesSlides ref={slidesRef}>
        {slides.map((slide, index) => (
          <TemplatsSlide {...slide} key={index} />
        ))}
      </TemplatesSlides>
    </Container>
  )
}

export default Templates
