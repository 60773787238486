import React from 'react'
import styled from '@emotion/styled'
import { withAttrs } from '../../../utils'
import { mediaQueries } from '../../../theme'
import { mainHeadingStyles } from '../../shared/type'
import StarsIcon from '../../../assets/icons/stars.svg'
import BaseContainer from '../../shared/container'
import Link from '../../shared/link'
import Button from '../../shared/button'

const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const Note = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  min-width: 12.75rem;
  padding: ${p => `${p.theme.space[1]} ${p.theme.space[3]}`};
  margin-bottom: ${p => p.theme.space[10]};
  font-size: ${p => p.theme.fontSizes[1]};
  color: ${p => p.theme.colors.blue[1]};
  outline: 1px solid;
  border-radius: ${p => p.theme.space[4]};
  text-transform: uppercase;
`

const Title = styled.h2`
  ${mainHeadingStyles}
  max-width: 28.75rem;
  margin: 0 0 ${p => p.theme.space[10]} 0;

  ${mediaQueries.lg} {
    max-width: 53.75rem;
  }
`

const StartButton = styled(withAttrs(Button, { as: Link }))`
  font-size: ${p => p.theme.fontSizes[3]};
  min-width: 16rem;
  width: 100%;
  padding: ${p => p.theme.space[5]};
  margin-bottom: ${p => p.theme.space[14]};

  ${mediaQueries.md} {
    max-width: max-content;
  }

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[16]};
  }
`

const Rating = styled.span`
  padding-bottom: ${p => p.theme.space[6]};
  background-image: url(${StarsIcon});
  background-repeat: no-repeat;
  background-size: auto 16px;
  background-position: center bottom;
  font-size: ${p => p.theme.fontSizes[1]};
  color: ${p => p.theme.colors.gray[11]};
`

const Hero = ({ className }) => {
  return (
    <Container className={className}>
      <Note>No website required</Note>
      <Title>Attract subscribers, send emails, and build automations.</Title>
      <StartButton to="/start/">Get started now →</StartButton>
      <Rating>Hundreds of small brands are using Getform</Rating>
    </Container>
  )
}

export default Hero
