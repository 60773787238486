import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../../theme'
import TemplateImageLink from './template-image-link'

const Container = styled.div`
  position: relative;

  ${mediaQueries.lg} {
    &:after {
      position: absolute;
      content: '';
      width: 92.4%;
      height: 113.32%;
      top: 35px;
      left: 9%;
      background-color: ${p => p.theme.colors.gray[2]};
      z-index: -1;
    }
  }
`

const Front = styled(TemplateImageLink)`
  display: none;
  width: 88.1%;
  background-color: ${p => p.theme.colors.white};
  box-shadow: ${p => p.theme.shadows.card};

  ${mediaQueries.lg} {
    display: block;
  }
`

const Back = styled(TemplateImageLink)`
  display: none;
  position: absolute;
  top: 230px;
  right: 30px;
  width: 36.3%;
  background-color: ${p => p.theme.colors.white};
  box-shadow: ${p => p.theme.shadows.card};

  ${mediaQueries.lg} {
    display: block;
  }
`

const Mobile = styled(TemplateImageLink)`
  display: block;
  width: 100%;

  ${mediaQueries.lg} {
    display: none;
  }
`

const TemplateStack = ({ front, back, mobile, wid, className }) => {
  return (
    <Container className={className}>
      <Front target={{ wid: wid, image: front }} defaultTheme="light" />
      <Back
        target={{ wid: wid, image: back }}
        defaultDevice="mobile"
        defaultTheme="light"
      />
      <Mobile
        target={{ wid: wid, image: mobile }}
        defaultDevice="mobile"
        defaultTheme="light"
      />
    </Container>
  )
}

export default React.memo(TemplateStack)
