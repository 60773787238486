import { useState, useCallback, useRef } from 'react'

const useIntersectionObserver = (opt = {}) => {
  const { threshold = 0.5, root = null, rootMargin = '0px' } = opt
  const [entry, setEntry] = useState(null)
  const prevObserver = useRef(null)

  const customRef = useCallback(
    node => {
      if (prevObserver.current) {
        prevObserver.current.disconnect()
        prevObserver.current = null
      }

      if (node?.nodeType === Node.ELEMENT_NODE) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            setEntry(entry)
          },
          { threshold, root, rootMargin },
        )

        observer.observe(node)
        prevObserver.current = observer
      }
    },
    [threshold, root, rootMargin],
  )

  return [customRef, entry]
}

export default useIntersectionObserver
