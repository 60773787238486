import React from 'react'
import styled from '@emotion/styled'

import { TalkToUsIcon } from 'assets/icons/social'

import { withAttrs } from '../../../utils'
import { gtmEvent } from '../../../utils/gtm'
import { mediaQueries } from '../../../theme'
import { H2 } from '../../shared/type'
import Button from '../../shared/button'
import Link from '../../shared/link'

const Container = styled.div`
  display: grid;
  grid-template-areas: 'feature' 'title' 'button' 'link';
  padding: ${p => `${p.theme.space[12]} ${p.theme.space[6]}`};

  ${mediaQueries.md} {
    padding: ${p => p.theme.space[14]};
  }

  ${mediaQueries.lg} {
    grid-template-areas: 'feature feature' 'title title' 'button link';
    grid-template-columns: 9.75rem auto;
    grid-column-gap: ${p => p.theme.space[10]};
    padding: ${p => `${p.theme.space[23]} ${p.theme.space[17]}`};
  }
`

const StartButton = styled(withAttrs(Button, { as: Link, to: '/start/' }))`
  grid-area: button;
  align-self: center;
  margin-bottom: ${p => p.theme.space[6]};

  ${mediaQueries.lg} {
    margin: 0;
  }
`

export const Title = styled(H2)`
  grid-area: title;
  margin: ${p => `0 0 ${p.theme.space[8]}`};
  color: ${p => p.theme.colors.white};

  ${mediaQueries.md} {
    font-size: ${p => p.theme.fontSizes[7]};
  }

  ${mediaQueries.lg} {
    margin: ${p => `0 0 ${p.theme.space[10]}`};
  }

  ${mediaQueries.xl} {
    font-size: ${p => p.theme.fontSizes[12]};
  }
`

const Feature = styled.div`
  grid-area: feature;
  margin-bottom: ${p => p.theme.space[5]};
  color: ${p => p.theme.colors.yellow[0]};
  text-transform: uppercase;

  ${mediaQueries.md} {
    margin-bottom: ${p => p.theme.space[5]};
  }
`

const Icon = styled.svg`
  margin-right: ${p => p.theme.space[3]};

  ${mediaQueries.lg} {
    display: none;
  }

  ${mediaQueries.xl} {
    display: block;
  }
`

const ChatLink = styled.span`
  grid-area: link;
  display: flex;
  justify-self: center;
  align-self: center;
  align-items: center;
  color: ${p => p.theme.colors.white};
  text-decoration: underline;
  cursor: pointer;

  ${mediaQueries.lg} {
    justify-self: flex-start;

    :hover {
      text-decoration: none;
    }
  }
`

const Cta = ({ className }) => (
  <Container className={className}>
    <Feature>Top-notch customer care</Feature>
    <Title>
      Send us an email or use live chat to get answers in real-time.
    </Title>
    <StartButton>Get started</StartButton>
    <ChatLink onClick={() => gtmEvent('showChat')}>
      <Icon as={TalkToUsIcon} />
      Talk to us
    </ChatLink>
  </Container>
)

export default Cta
