import React from 'react'
import styled from '@emotion/styled'

const Icon = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: ${p => p.theme.space[3]};
`

const Title = styled.div`
  border-bottom: 1px solid transparent;
`

const Link = styled.a`
  display: flex;
  font-size: ${p => p.theme.fontSizes[1]};
  line-height: ${p => p.theme.lineHeights.button};
  cursor: pointer;
  color: inherit;
  text-decoration: none;

  :hover,
  :focus {
    ${Title} {
      border-color: inherit;
    }
  }
`

const IconLink = ({ children, href, icon, className}) => (
  <Link href={href} className={className}>
    {icon && <Icon as={icon} />}
    <Title>
      {children}
    </Title>
  </Link>
)

export default IconLink
