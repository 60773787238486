import React, { Children } from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { useCarousel } from './carousel'

const Container = styled.div`
  &.is-draggable {
    cursor: move;
    cursor: grab;
  }

  &.is-dragging {
    cursor: grabbing;
  }
`

const Slides = styled.div`
  display: flex;
  user-select: none;

  ${mediaQueries.md} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(320px, min-content);
    border: 2px solid ${p => p.theme.colors.gray[12]};
    user-select: text;
  }
`

const Slide = styled.div`
  border: 2px solid ${p => p.theme.colors.gray[12]};
  border-right-style: dashed;
  border-right-width: 1px;

  &:not(:first-of-type) {
    border-left: none;
  }

  &:last-of-type {
    border-right-style: solid;
    border-right-width: 2px;
  }

  ${mediaQueries.md} {
    border-top: none;
    border-width: 1px;
    border-bottom-style: dashed;

    &:first-of-type {
      border-left: none;
    }

    &:nth-of-type(n + 4) {
      border-bottom: none;
    }

    &:nth-of-type(3n) {
      border-right: none;
    }
  }
`

const options = {
  desktop: {
    draggable: false,
  },
}

export const CarouselGrid = ({ children, storageKey, className }) => {
  const { slidesRef } = useCarousel(storageKey, options)

  return (
    <Container ref={slidesRef} className={className}>
      <Slides>
        {Children.map(children, (child, index) => (
          <Slide key={index}>{child}</Slide>
        ))}
      </Slides>
    </Container>
  )
}

export default CarouselGrid
