import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../../theme'
import { mainHeadingStyles } from '../../shared/type'
import Container from '../../shared/container'
import FeaturedImage from '../shared/featured-image'
import { MediaCard } from '../../shared/cards'
import MediaCardCarousel from '../shared/media-card-carousel'

const Title = styled.h2`
  ${mainHeadingStyles};
  margin: 0 0 ${p => p.theme.space[6]} 0;

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[14]};
  }
`

const Emails = ({
  data: { mainImageDesktop, mainImageMobile, cards },
  className,
}) => {
  return (
    <Container className={className}>
      <Title>Send email campaigns</Title>
      <FeaturedImage
        desktop={mainImageDesktop}
        mobile={mainImageMobile}
        alt="Send email campaigns"
      />
      <MediaCardCarousel storageKey="send-email-campaigns-carousel">
        {cards.map((card, idx) => (
          <MediaCard key={idx} {...card} />
        ))}
      </MediaCardCarousel>
    </Container>
  )
}

export default Emails
