import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../../theme'
import Container from '../../shared/container'
import { mainHeadingStyles } from '../../shared/type'
import { MediaCard } from '../../shared/cards'

const Title = styled.h2`
  ${mainHeadingStyles};
  margin: 0 0 ${p => p.theme.space[6]} 0;

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[14]};
  }
`

const Cards = styled.div`
  display: grid;
  grid-template-columns: minmax(250px, 1fr);
  gap: 1rem;

  ${mediaQueries.md} {
    grid-template-columns: repeat(2, minmax(250px, 588px));
    gap: 2rem;
    user-select: auto;
  }
`

const Leads = ({ className, items }) => {
  return (
    <Container className={className}>
      <Title>Turn followers into leads</Title>
      <Cards>
        {items.map((item, index) => (
          <MediaCard key={index} {...item}></MediaCard>
        ))}
      </Cards>
    </Container>
  )
}

export default Leads
