import EmailLinkIcon from 'assets/icons/automations/email-link.svg'
import FormSubmissionIcon from 'assets/icons/automations/form-submission.svg'
import NewSubscriberIcon from 'assets/icons/automations/new-subscriber.svg'
import OrderPaymentIcon from 'assets/icons/automations/order-payment.svg'
import SegmentIcon from 'assets/icons/automations/segment.svg'
import WebhookRequestIcon from 'assets/icons/automations/webhook-request.svg'

const ICONS = {
  email: EmailLinkIcon,
  form: FormSubmissionIcon,
  subscriber: NewSubscriberIcon,
  payment: OrderPaymentIcon,
  segment: SegmentIcon,
  webhook: WebhookRequestIcon,
}

export default ICONS
