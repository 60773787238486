import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../../theme'
import ArtDirectedImage from '../../shared/art-directed-image'

const Image = styled(ArtDirectedImage)`
  display: block;
  width: 100%;
  margin-bottom: ${p => p.theme.space[4]};
  border: 2px solid ${p => p.theme.colors.gray[12]};

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[8]};
  }
`

const FeaturedImage = ({ desktop, mobile, alt, className }) => {
  return (
    <Image className={className} desktop={desktop} mobile={mobile} alt={alt} />
  )
}

export default FeaturedImage
