import React from 'react'
import styled from '@emotion/styled'

import useSiteMetadata from '../../hooks/use-site-metadata'
import { mediaQueries } from '../../theme'
import Header from '../header'
import Footer from '../footer'
import { BaseLayout } from '../layout'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Main = styled.main`
  flex: 1 1 auto;
  overflow: hidden;
  padding-top: ${p => p.theme.space[21]};
  background: top center / 2000px 30rem no-repeat
    radial-gradient(
      ellipse at 45% 0%,
      rgba(246, 246, 248, 1) 0%,
      rgba(246, 246, 248, 1) 47%,
      rgba(0, 0, 0, 0) 69%,
      rgba(0, 0, 0, 0) 101%
    );

  ${mediaQueries.lg} {
    padding-top: ${p => p.theme.space[24]};
    background-size: 3000px 36rem;
  }
`

const Layout = ({ children, location }) => {
  const { title } = useSiteMetadata()
  return (
    <BaseLayout location={location}>
      <Wrapper>
        <Header siteTitle={title} variant="nav" />
        <Main>{children}</Main>
        <Footer siteTitle={title} />
      </Wrapper>
    </BaseLayout>
  )
}

export default Layout
