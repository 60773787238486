import React from 'react'
import styled from '@emotion/styled'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg'
import { mediaQueries } from '../../../theme'

const Container = styled.div`
  padding: ${p => `${p.theme.space[3]} ${p.theme.space[5]}`};
  background: ${p => p.selected && p.theme.colors.gray[2]};
  cursor: pointer;

  ${mediaQueries.lg} {
    :hover {
      background: ${p => p.theme.colors.gray[2]};
    }
  }

  ${p =>
    p.size === 'medium' &&
    `
    ${mediaQueries.lg}{
      padding: ${p.theme.space[6]} ${p.theme.space[8]};
      padding-bottom: ${p.selected && p.theme.space[8]};
    }
  `}
`

export const Title = styled.h3`
  margin: 0;
  font-size: ${p => p.theme.fontSizes[2]};
  line-height: ${p => p.theme.lineHeights.default};
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;

  ${mediaQueries.lg} {
    border-bottom: ${p =>
      `2px dotted ${!p.selected ? p.theme.colors.black[0] : 'transparent'}`};
    margin-right: ${p => `${p.theme.space[3]}`};
  }

  ${p =>
    p.size === 'medium' &&
    `
    ${mediaQueries.lg}{
      font-size: ${p.theme.fontSizes[5]};
      margin-right: ${p.theme.space[4]};
    }
  `}
`

const Header = styled.div`
  display: flex;
  align-items: center;
`

const Arrow = styled(ArrowIcon)`
  display: none;
  width: 0.625rem;
  transform: ${p => p.direction === 'top' && 'rotate(180deg)'};
  transform-origin: 50% 6px;

  ${mediaQueries.lg} {
    display: block;
  }
`

const Icon = styled.svg`
  min-width: ${p => (p.size === 'medium' ? '1.75rem' : '1.25rem')};
  width: ${p => (p.size === 'medium' ? '1.75rem' : '1.25rem')};
  height: ${p => (p.size === 'medium' ? '1.75rem' : '1.25rem')};
  margin: ${p => `0 ${p.theme.space[4]} 0 0`};
`

const Description = styled.p`
  margin: ${p => `${p.theme.space[2]} 0 ${p.theme.space[1]} 0`};
  font-size: ${p => p.theme.fontSizes[1]};

  ${mediaQueries.lg} {
    display: ${p => (p.selected ? 'block' : 'none')};
    margin: ${p => p.size === 'medium' && `${p.theme.space[3]} 0`};
    font-size: ${p => p.size === 'medium' && p.theme.fontSizes[2]};
  }
`

const Content = styled.div`
  margin: ${p => `${p.theme.space[2]} 0 ${p.theme.space[1]} 0`};

  ${mediaQueries.lg} {
    display: ${p => (p.selected ? 'block' : 'none')};
  }
`

const Thumbnail = ({
  title,
  selected,
  icon,
  description,
  children,
  size = 'medium',
  ...rest
}) => (
  <Container size={size} selected={selected} {...rest}>
    <Header>
      {icon && <Icon size={size} as={icon} />}
      <Title size={size} selected={selected}>
        {title}
      </Title>
      {description && <Arrow direction={selected ? 'top' : 'down'} />}
    </Header>
    {description && (
      <Description selected={selected}>{description}</Description>
    )}
    {children && <Content selected={selected}>{children}</Content>}
  </Container>
)

export default Thumbnail
