import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'
import { mediaQueries } from '../../../theme'
import { BaseTemplateLink } from '../../shared/templates/template-link'

const LinkBody = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: ${p => `2px solid ${p.theme.colors.white}`};
  box-shadow: 0px 0px 0px 2px ${p => p.theme.colors.black[0]};
  font-size: ${p => p.theme.fontSizes[2]};
  color: ${p => p.theme.colors.white};
  background: rgba(0, 0, 0, 0.8);
`

const LinkText = styled.span`
  max-width: 20.25rem;
`

const TemplateImageLink = ({
  target,
  templates,
  defaultDevice,
  loading = 'lazy',
  defaultTheme,
  children,
  ...rest
}) => (
  <BaseTemplateLink
    target={target}
    templates={templates}
    defaultDevice={defaultDevice}
    defaultTheme={defaultTheme}
    ariaLabel="A link to the template"
    css={css`
      position: relative;
      display: flex;
      ${mediaQueries.lg} {
        :hover {
          z-index: 1;
        }
        :hover ${LinkBody} {
          display: flex;
        }
      }
    `}
    {...rest}
  >
    <GatsbyImage
      image={getImage(target.image)}
      alt={`Link to template with id ${target.wid}`}
      loading={loading}
    />
    <LinkBody>
      <LinkText>{children || 'See live preview'}</LinkText>
    </LinkBody>
  </BaseTemplateLink>
)

export default TemplateImageLink
