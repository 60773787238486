import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../../theme'
import ShareCarousel from './share-carousel'
import { H2 } from '../../shared/type'
import { mainHeadingStyles } from '../../shared/type'

const Container = styled.div``

const Title = styled(H2)`
  ${mainHeadingStyles}
  padding: 0 ${p => p.theme.space[6]};
  margin: ${p => `0 0 ${p.theme.space[8]}`};
  line-height: 1.27;

  ${mediaQueries.md} {
    padding: 0 ${p => p.theme.space[10]};
  }

  ${mediaQueries.lg} {
    padding: 0;
    margin: ${p => `0 0 ${p.theme.space[14]}`};
  }
`

const Share = ({ items, className }) => (
  <Container className={className}>
    <Title>Where to share your forms</Title>
    <ShareCarousel items={items} />
  </Container>
)

export default Share
