import React from 'react'
import styled from '@emotion/styled'

import { mediaQueries } from '../../../theme'
import Container from '../../shared/container'
import { mainHeadingStyles } from '../../shared/type'
import { Thumbnails, Slides, useCarousel } from '../../shared/carousel'
import Thumbnail from '../shared/thumbnail'
import Slide from './slide'
import IconLink from '../../shared/icon-link'

import {
  ArtistsIcon,
  CoachesIcon,
  FreelancersIcon,
  CourseCreatorsIcon,
  ChefsIcon,
  MakersIcon,
  MusiciansIcon,
  PodcastersIcon,
  WritersIcon,
  YouTubersIcon,
} from 'assets/icons/creator'

const ICONS = {
  artists: ArtistsIcon,
  coaches: CoachesIcon,
  freelancers: FreelancersIcon,
  'course-creators': CourseCreatorsIcon,
  chefs: ChefsIcon,
  makers: MakersIcon,
  musicians: MusiciansIcon,
  podcasters: PodcastersIcon,
  writers: WritersIcon,
  youtubers: YouTubersIcon,
}

const CreatorsContainer = styled(Container)`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  padding: ${p => p.theme.space[12]} 0 0;
  margin: ${p => `0 auto ${p.theme.space[24]} auto`};

  ${mediaQueries.lg} {
    grid-template-columns: 384px 1fr 1fr;
    grid-template-areas:
      'title title link'
      'thumbnails slides slides';
    gap: ${p => p.theme.space[9]} 0;
    align-items: start;
    margin-bottom: ${p => p.theme.space[27]};
    padding: ${p =>
      `${p.theme.space[5]} ${p.theme.space[6]} 0 ${p.theme.space[10]}`};
  }
`

const Title = styled.h2`
  ${mainHeadingStyles}
  margin: ${p => `0 ${p.theme.space[6]} ${p.theme.space[8]}`};
  text-align: left;

  ${mediaQueries.md} {
    margin: ${p =>
      `0 ${p.theme.space[10]} ${p.theme.space[8]} ${p.theme.space[10]}`};
  }

  ${mediaQueries.lg} {
    grid-area: title;
    margin: 0;
    align-self: end;
  }
`

const Link = styled(IconLink)`
  grid-row-start: 4;
  grid-row-end: 5;
  justify-content: center;
  align-self: center;
  justify-self: center;
  margin: ${p =>
    `${p.theme.space[2]} ${p.theme.space[6]} 0 ${p.theme.space[6]}`};

  ${mediaQueries.lg} {
    grid-area: link;
    justify-self: end;
    margin: 0 ${p => p.theme.space[4]};
  }
`

const CreatorsThumbnails = styled(Thumbnails)`
  margin: ${p => `0 ${p.theme.space[6]}`};

  ${mediaQueries.md} {
    margin: ${p => `0 ${p.theme.space[10]}`};
  }

  ${mediaQueries.lg} {
    grid-area: thumbnails;
    width: max-content;
    min-width: 15rem;
    margin: ${p => `${p.theme.space[5]} 0 0`};
  }
`

const CreatorsThumbnail = styled(Thumbnail)`
  margin-right: ${p => p.theme.space[2]};
  min-width: 74%;

  &:last-child {
    margin-right: 0;
  }

  ${mediaQueries.md} {
    min-width: 40%;
  }

  ${mediaQueries.lg} {
    margin: ${p => `0 0 ${p.theme.space[2]} 0`};

    &:last-child {
      margin: 0;
    }
  }
`

const CreatorsSlides = styled(Slides)`
  margin: ${p => `0 ${p.theme.space[1]}`};
  padding: ${p => `${p.theme.space[4]} 0 0`};

  ${mediaQueries.lg} {
    grid-area: slides;
    margin: 0;
    padding: 0;
  }
`
const CreatorsSlide = styled(Slide)`
  min-width: 100%;
`

const Creators = ({ items }) => {
  const { slidesRef, thumbnailsRef, selectedIndex, select } = useCarousel(
    'creators-carousel',
    {
      desktop: {
        draggable: false,
        speed: 100,
      },
    },
  )

  const thumbnails = items.map(({ title, name }) => {
    return { title, name, icon: ICONS[name] }
  })

  const slides = items.map(({ desktopTemplates, mobileTemplate }) => {
    return { desktopTemplates, mobileTemplate }
  })

  const selectedThumbnail = thumbnails[selectedIndex]

  return (
    <CreatorsContainer>
      <Title>Forms for creators</Title>
      <Link
        href={'/templates/creators/' + selectedThumbnail.name}
        icon={selectedThumbnail.icon}
      >
        {selectedThumbnail.title} templates →
      </Link>
      <CreatorsThumbnails ref={thumbnailsRef}>
        {thumbnails.map((thumb, index) => (
          <CreatorsThumbnail
            key={index}
            {...thumb}
            size="small"
            selected={index === selectedIndex}
            onClick={() => select(index)}
          ></CreatorsThumbnail>
        ))}
      </CreatorsThumbnails>
      <CreatorsSlides ref={slidesRef}>
        {slides.map((slide, index) => (
          <CreatorsSlide {...slide} key={index} />
        ))}
      </CreatorsSlides>
    </CreatorsContainer>
  )
}

export default Creators
