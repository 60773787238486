import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../../theme'
import { CardCarousel } from '../../shared/cards'

const DefaultSlide = styled.div`
  min-width: 282px;

  & + & {
    margin-left: ${p => p.theme.space[3]};
  }

  ${mediaQueries.md} {
    min-width: 200px;
    max-width: 378px;
    user-select: text;
  }

  ${mediaQueries.lg} {
    & + & {
      margin-left: ${p => p.theme.space[8]};
    }
  }
`

const options = {
  desktop: {
    draggable: false,
  },
}

export const MediaCardCarousel = ({
  children,
  storageKey,
  className,
  Slide,
}) => {
  return (
    <CardCarousel
      thumbnails={false}
      storageKey={storageKey}
      Slide={Slide ?? DefaultSlide}
      options={options}
      className={className}
    >
      {children}
    </CardCarousel>
  )
}

export default MediaCardCarousel
