import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { mediaQueries } from '../../../theme'
import { withAttrs } from '../../../utils'
import BaseTemplateImageLink from '../shared/template-image-link'
import ArrowBold from 'assets/icons/arrow-bold.svg'

const arrowStartStyles = css`
  position: relative;

  :before {
    content: '';
    position: absolute;
    width: 12%;
    height: 13%;
    background-size: contain;
    background-repeat: no-repeat;
    filter: drop-shadow(2px 5px 10px rgba(0, 0, 0, 0.3));
    z-index: 1;
    opacity: 0;
    transition: all 0s;
    will-change: transform, opacity;
  }

  :nth-of-type(1):before {
    transform: translate(234%, 109%) rotate(-90deg);
  }

  :nth-of-type(2):before {
    transform: translate(290%, 550%);
    ${mediaQueries.lg} {
      transform: translate(275%, 470%);
    }
  }

  :nth-of-type(3):before {
    transform: translate(310%, 510%);
    ${mediaQueries.lg} {
      transform: translate(290%, 415%);
    }
  }

  :nth-of-type(4):before {
    transform: translate(290%, 700%);
    ${mediaQueries.lg} {
      transform: translate(305%, 615%);
    }
  }

  :nth-of-type(5):before {
    transform: translate(300%, 440%) rotate(-90deg);
  }

  :nth-of-type(6):before {
    transform: translate(235%, 380%);
    ${mediaQueries.lg} {
      transform: translate(280%, 315%);
    }
  }

  :nth-of-type(7):before {
    transform: translate(315%, 445%) rotate(-90deg);
  }

  :nth-of-type(8):before {
    transform: translate(275%, 620%);
    ${mediaQueries.lg} {
      transform: translate(300%, 540%);
    }
  }

  :nth-of-type(9):before {
    transform: translate(320%, 410%) rotate(-90deg);
  }

  :nth-of-type(10):before {
    transform: translate(200%, 540%);
    ${mediaQueries.lg} {
      transform: translate(190%, 450%);
    }
  }

  :nth-of-type(11):before {
    transform: translate(315%, 480%) rotate(-90deg);
  }
`

const arrowEndStyles = css`
  :before {
    opacity: 1;
    background-image: url(${ArrowBold});
    transition: all 1s ease 0.5s, opacity 0.5s ease 0.5s;
  }

  :nth-of-type(1):before {
    transform: translate(180%, 210%) rotate(-90deg);
    ${mediaQueries.lg} {
      transform: translate(150%, 165%) rotate(-90deg);
    }
  }

  :nth-of-type(2):before {
    transform: translate(240%, 460%);
    ${mediaQueries.lg} {
      transform: translate(200%, 410%);
    }
  }

  :nth-of-type(3):before {
    transform: translate(260%, 410%);
    ${mediaQueries.lg} {
      transform: translate(218%, 360%);
    }
  }

  :nth-of-type(4):before {
    transform: translate(240%, 600%);
    ${mediaQueries.lg} {
      transform: translate(225%, 555%);
    }
  }

  :nth-of-type(5):before {
    transform: translate(270%, 545%) rotate(-90deg);
    ${mediaQueries.lg} {
      transform: translate(225%, 495%) rotate(-90deg);
    }
  }

  :nth-of-type(6):before {
    transform: translate(185%, 285%);
    ${mediaQueries.lg} {
      transform: translate(185%, 245%);
    }
  }

  :nth-of-type(7):before {
    transform: translate(280%, 550%) rotate(-90deg);
    ${mediaQueries.lg} {
      transform: translate(230%, 510%) rotate(-90deg);
    }
  }

  :nth-of-type(8):before {
    transform: translate(225%, 520%);
    ${mediaQueries.lg} {
      transform: translate(225%, 475%);
    }
  }

  :nth-of-type(9):before {
    transform: translate(255%, 520%) rotate(-90deg);
    ${mediaQueries.lg} {
      transform: translate(225%, 473%) rotate(-90deg);
    }
  }

  :nth-of-type(10):before {
    transform: translate(150%, 440%);
    ${mediaQueries.lg} {
      transform: translate(110%, 390%);
    }
  }

  :nth-of-type(11):before {
    transform: translate(270%, 590%) rotate(-90deg);
    ${mediaQueries.lg} {
      transform: translate(235%, 540%) rotate(-90deg);
    }
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${p => p.theme.space[4]};
  padding: ${p => `${p.theme.space[10]} 5px 5px 0`};

  ${arrowStartStyles}

  ${p => p.isInViewport && p.selected && arrowEndStyles}
  
  ${mediaQueries.lg} {
    grid-column-gap: ${p => p.theme.space[14]};
    padding: ${p =>
      `${p.theme.space[10]} ${p.theme.space[15]} ${p.theme.space[15]} 0`};
  }
`

const outlineStyles = ({ theme }) => css`
  border: 2px solid black;
  background-color: ${theme.colors.white};
  box-shadow: 5px 5px 0px 0px black;

  ${mediaQueries.lg} {
    box-shadow: 11px 11px 0px 0px black;
  }
`

const Image = styled(withAttrs(GatsbyImage, { objectFit: 'contain' }))`
  ${outlineStyles}
`

const TemplateImageLink = styled(BaseTemplateImageLink)`
  ${outlineStyles}
`

const Slide = ({
  usecaseImage,
  templateImage,
  templates,
  wid,
  selected,
  isInViewport,
  ...rest
}) => (
  <Container isInViewport={isInViewport} selected={selected} {...rest}>
    <Image image={getImage(usecaseImage)} alt="Share" loading="lazy" />
    <TemplateImageLink
      target={{ wid, image: getImage(templateImage) }}
      templates={templates}
      defaultDevice="mobile"
    />
  </Container>
)

export default Slide
