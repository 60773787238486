import React from 'react'
import styled from '@emotion/styled'
import useIntersectionObserver from '../../../hooks/use-intersection-observer'

import {
  TwitterIcon,
  InstagramIcon,
  TiktokIcon,
  EmailIcon,
  YoutubeIcon,
  MessengersIcon,
  WebsiteIcon,
  IntercomIcon,
  SpotifyIcon,
  FacebookIcon,
  BlogIcon,
} from 'assets/icons/social'

import { mediaQueries } from '../../../theme'
import { Thumbnails, Slides, useCarousel } from '../../shared/carousel'

import Thumbnail from '../shared/thumbnail'
import Slide from './slide'

const ICONS = {
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  tiktok: TiktokIcon,
  'email-to-subscribers': EmailIcon,
  youtube: YoutubeIcon,
  messengers: MessengersIcon,
  website: WebsiteIcon,
  intercom: IntercomIcon,
  spotify: SpotifyIcon,
  facebook: FacebookIcon,
  blog: BlogIcon,
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  align-items: start;

  ${mediaQueries.lg} {
    grid-template-columns: 18.75rem 1fr;
    grid-column-gap: ${p => p.theme.space[14]};
  }
`

const ShareSlide = styled(Slide)`
  min-width: 100%;
  padding: ${p =>
    `${p.theme.space[10]} ${p.theme.space[6]} ${p.theme.space[1]}`};

  ${mediaQueries.md} {
    padding: ${p =>
      `${p.theme.space[10]} ${p.theme.space[10]} ${p.theme.space[1]}`};
  }

  ${mediaQueries.lg} {
    padding: ${p =>
      `${p.theme.space[10]} ${p.theme.space[14]} ${p.theme.space[15]} ${p.theme.space[1]}`};
  }
`

const ShareThumbnails = styled(Thumbnails)`
  margin: ${p => `0 ${p.theme.space[6]}`};

  ${mediaQueries.md} {
    margin: ${p => `0 ${p.theme.space[10]}`};
  }

  ${mediaQueries.lg} {
    margin: 0;
  }
`

const ShareThumbnail = styled(Thumbnail)`
  margin-right: ${p => p.theme.space[3]};
  min-width: 78.5%;

  ${mediaQueries.md} {
    min-width: 40%;
  }

  ${mediaQueries.lg} {
    min-width: 100%;
    margin: ${p => `0 0 ${p.theme.space[1]} 0`};
  }
`

const ShareSlides = styled(Slides)`
  ${mediaQueries.lg} {
    background: linear-gradient(
      90deg,
      ${p => p.theme.colors.white} 8%,
      ${p => p.theme.colors.gray[2]} 8%
    );
  }
`

const ShareCarousel = ({ items, className }) => {
  const { slidesRef, thumbnailsRef, selectedIndex, select } = useCarousel(
    'share-carousel',
    {
      desktop: {
        draggable: false,
        speed: 100,
      },
    },
  )

  const templates = items.map(({ wid }) => ({ wid }))
  const thumbnails = items.map(({ title, description, name }) => ({
    title,
    description,
    icon: ICONS[name],
  }))
  const slides = items.map(({ usecaseImage, templateImage, wid }) => ({
    usecaseImage,
    templateImage,
    wid,
  }))

  const [ref, entry] = useIntersectionObserver()

  return (
    <Container className={className} ref={ref}>
      <ShareThumbnails ref={thumbnailsRef}>
        {thumbnails.map((thumb, index) => (
          <ShareThumbnail
            key={index}
            {...thumb}
            size="small"
            selected={index === selectedIndex}
            onClick={() => select(index)}
          />
        ))}
      </ShareThumbnails>

      <ShareSlides ref={slidesRef}>
        {slides.map((slide, index) => (
          <ShareSlide
            {...slide}
            templates={templates}
            selected={index === selectedIndex}
            isInViewport={entry?.isIntersecting}
            key={index}
          />
        ))}
      </ShareSlides>
    </Container>
  )
}

export default ShareCarousel
